<template>
    <div class="c-app flex-row align-items-center" :style="Style.Background">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol xl="4" lg="5" md="6" sm="8" col="10">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm @submit.prevent="DoLogin()">
                                    <img id="logo" v-lazy="Logo" alt="logo" class="mx-auto d-block mb-4">
                                    <h2 class="text-center font-weight-bold mb-4">{{ $t('Login.PageTitle') }}</h2>
                                    <CInput
                                            v-model="Account"
                                            :placeholder="$t('Login.Account')"
                                            type="email"
                                            autocomplete="username email"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-user"/>
                                        </template>
                                    </CInput>
                                    <CInput
                                            v-model="Password"
                                            :placeholder="$t('Login.Password')"
                                            type="password"
                                            autocomplete="curent-password"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-lock-locked"/>
                                        </template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="12" class="text-left">
                                            <CButton color="dark" class="px-4" id="LoginButton" block type="submit" :style="Style.Button">
                                                {{ $t('Login.DoLogin') }}
                                            </CButton>
                                            <CElementCover
                                                    v-if="(Submit === true)"
                                                    :boundaries="[{ sides: ['center', 'center'], query: '#LoginButton' }]"
                                                    :opacity="0.8"
                                            >
                                                <CSpinner size="sm" color="primary"/>
                                            </CElementCover>
                                        </CCol>
                                        <CCol col="12" class="text-center">
                                            <CButton color="link" class="px-0 mt-2" @click="ResetPasswordModal = true" :style="Style.Text">
                                                {{ $t('Login.Forget') }}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
        <CModal
                :show.sync="ResetPasswordModal"
                :centered="true"
                :title="$t('Login.ResetPassword')"
                color="dark"
        >
            <CForm>
                <CInput
                        v-model="Account"
                        :placeholder="$t('Login.Account')"
                        type="email"
                        :description="$t('Login.ResetPasswordDescription')"
                        autocomplete="username email"
                >
                </CInput>
            </CForm>
            <template #footer>
                <CButton @click="ResetPasswordModal = false" color="secondary">{{ $t('Global.Cancel') }}</CButton>
                <CButton @click="ResetPassword()" color="success">{{ $t('Login.SendVerifiedMail') }}</CButton>
            </template>
        </CModal>
    </div>
</template>

<route>
{
    "meta": {
        "label": "登入"
    }
}
</route>

<script>
export default {
  name: 'Login',
  data() {
    return {
      Account: '',
      Password: '',
      ResetPasswordModal: false,
      Submit: false,
      Logo: '',
      HostName: '',
      Style: {}
    }
  },
  mounted() {
    this.ParsingHostname()
    // if (process.env.NODE_ENV === 'production') {
    //   this.ParsingHostname()
    // } else {
    //   this.Logo = this.$store.state.defaultLogo
    // }
  },
  methods: {
    DoLogin() {
      this.Submit = true
      const setPersistence = this.$Firebase('auth')
          .setPersistence(this.$Firebase('app').auth.Auth.Persistence.LOCAL)
          .then(() => {
            return true
          })
          .catch((err) => {
            throw err
          })
      const sign = this.$Firebase('auth')
          .signInWithEmailAndPassword(this.Account, this.Password)
          .then(() => {
            // return this.$store.dispatch('InnerRequest', {
            //   url: '/user/login',
            //   method: 'get',
            // }).then(() => {
            //   return true
            // })
          }).catch((err) => {
            throw err
          })
      return Promise.all([setPersistence, sign]).then(() => {
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Login/Success'),
          type: 'success'
        })
      }).catch((err) => {
        console.error(err)
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.' + err.code),
          type: 'error'
        })
      })
    },
    ResetPassword() {
      this.$Firebase('auth').sendPasswordResetEmail(this.Account).then(() => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Login/ResendSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.' + err.code),
          type: 'error'
        })
        this.ResetPasswordModal = false
      })
    },
    ParsingHostname() {
      let Domain = window.location.hostname.replace('www.', '').replace('admin.', '')
      if (localStorage.getItem('AdminProfile')) {
        this.Logo = JSON.parse(localStorage.getItem('AdminProfile')).Logo
        this.Style = {
          Button: {
            backgroundColor: JSON.parse(localStorage.getItem('AdminProfile')).ThemeColor['primary-light'],
            borderColor: JSON.parse(localStorage.getItem('AdminProfile')).ThemeColor['primary-light']
          },
          Background: {
            backgroundColor: JSON.parse(localStorage.getItem('AdminProfile')).ThemeColor.primary
          },
          Text: {
            color: JSON.parse(localStorage.getItem('AdminProfile')).ThemeColor.primary
          },
          Input: {
            boxShadow: 'initial'
          }
        }
        this.$store.commit('setThemeColor', JSON.parse(localStorage.getItem('AdminProfile')).ThemeColor)
        return Promise.resolve(true)
      } else {
        return this.$Firebase('firestore').collection('AdminProfile').doc(Domain).get().then(doc => {
          if (doc.exists) {
            this.Logo = doc.data().Logo
            this.Style = {
              Button: {
                backgroundColor: doc.data().ThemeColor.primary,
                borderColor: doc.data().ThemeColor['primary-light']
              },
              Background: {
                backgroundColor: doc.data().ThemeColor['primary-light'],
              },
              Text: {
                color: doc.data().ThemeColor.primary
              },
              Input: {
                boxShadow: 'initial'
              }
            }
            this.$store.commit('setThemeColor', doc.data().ThemeColor)
            localStorage.setItem('AdminProfile', JSON.stringify(doc.data()))
          } else {
            this.Logo = this.$store.state.defaultLogo
          }
        }).catch((err) => {
          console.error(err)
          this.Logo = this.$store.state.defaultLogo
        })
      }
    }
  }
}
</script>

<style scoped>
#logo {
  max-width: 240px;
  max-height: 100px;
}
</style>
